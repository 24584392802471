import listParametersMixin from './list.parameters.mixin';
import itemsPerPageOptions from '../../../enums/items.per.page.options';

export default {
  data() {
    return {
      options: {},
      filters: {},
      footerProps: {
        'items-per-page-options': itemsPerPageOptions
      }
    };
  },
  mixins: [listParametersMixin],
  watch: {
    options() {
      this.getItems(this.mapListParameters(this.filters, this.options));
    }
  },
  created() {
    this.filters = this.getListParametersFromUrl();
  },
  methods: {
    getPage(toggleFirstPage) {
      let currentPage = this.options.page;

      if (toggleFirstPage) {
        currentPage = 1;
      } else {
        currentPage =
          this.items.length > 0 ? currentPage : this.options.page - 1;
      }

      return currentPage || 1;
    },
    resetList(toggleFirstPage = true) {
      let currentPage = this.getPage(toggleFirstPage);

      if (currentPage === this.options.page) {
        this.getItems(this.mapListParameters(this.filters, this.options));
      } else {
        this.options.page = currentPage;
      }
    },
    applyFilters(filters) {
      this.filters = filters;
      this.setListParametersToUrl(this.filters);
      this.resetList();
    }
  }
};
