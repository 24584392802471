<template>
  <v-container fluid>
    <v-card class="border-top-blue">
      <v-card-text>
        <avatar-categories-list></avatar-categories-list>
      </v-card-text>
    </v-card>
  </v-container>
</template>

<script>
import AvatarCategoriesList from '../components/AvatarCategoriesList';

export default {
  name: 'avatar-categories-page',
  components: {
    AvatarCategoriesList
  }
};
</script>
