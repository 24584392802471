<template>
  <fragment>
    <v-data-table
      :headers="headers"
      :items="items"
      :options.sync="options"
      :server-items-length="totalItems"
      :footer-props="footerProps"
      :loading="loading"
      :loading-text="$t('common.loadingData')"
      :no-data-text="$t('common.noData')"
      class="elevation-1"
    >
      <template v-slot:top>
        <v-toolbar flat color="white" class="mb-4">
          <v-toolbar-title>
            {{ $t('avatarCategories.categories') }}
          </v-toolbar-title>
          <div class="flex-grow-1"></div>
          <v-btn small fab depressed color="primary" @click="createCategory()">
            <v-icon>mdi-plus</v-icon>
          </v-btn>
        </v-toolbar>
        <avatar-categories-list-filter
          :apply-filters="applyFilters"
          :applied-filters="filters"
        ></avatar-categories-list-filter>
      </template>
      <template v-slot:item.name="{ item }">
        {{ item.name[currentUser.lang] | truncate(50) }}
      </template>
      <template v-slot:item.status="{ item }">
        <v-tooltip top :color="getApprovalColor(item.status)">
          <template v-slot:activator="{ on }">
            <v-icon x-small :color="getApprovalColor(item.status)" v-on="on">
              mdi-circle
            </v-icon>
          </template>
          <span>{{ item.status }}</span>
        </v-tooltip>
      </template>
      <template v-slot:item.createdBy="{ item }">
        <span>
          {{ item.createdBy | truncate(20) }}
        </span>
      </template>
      <template v-slot:item.action="{ item }">
        <v-btn
          x-small
          fab
          depressed
          color="primary"
          class="mr-1"
          @click="updateCategory(item)"
        >
          <v-icon>mdi-pencil</v-icon>
        </v-btn>
        <v-btn
          x-small
          fab
          depressed
          color="error"
          class="mr-1"
          @click="deleteCategory(item.id)"
        >
          <v-icon>mdi-delete</v-icon>
        </v-btn>
      </template>
    </v-data-table>
  </fragment>
</template>

<script>
import { mapGetters } from 'vuex';
import ManageAvatarCategoryModal from './ManageAvatarCategoryModal';
import AvatarCategoriesListFilter from './AvatarCategoriesListFilter';
import ConfirmModal from '../../shared/components/ConfirmModal';
import mapper from '../mappers/avatar.categories.mapper';
import httpService from '../services/avatar.categories.http.service';
import approvalStatusesMixin from '../../shared/mixins/approval.statuses.mixin';
import listItemsMixin from '../../shared/mixins/list.items.mixin';

export default {
  name: 'avatar-categories-list',
  components: {
    AvatarCategoriesListFilter
  },
  computed: {
    ...mapGetters(['currentUser'])
  },
  mixins: [listItemsMixin, approvalStatusesMixin],
  data() {
    return {
      loading: true,
      items: [],
      totalItems: 0,
      headers: [
        {
          text: this.$i18n.t('common.name'),
          value: 'name',
          sortable: false
        },
        {
          text: this.$i18n.t('common.status'),
          value: 'status',
          sortable: false
        },
        {
          text: this.$i18n.t('common.createdBy'),
          value: 'createdBy',
          sortable: false
        },
        {
          text: this.$i18n.t('common.actions'),
          value: 'action',
          sortable: false
        }
      ]
    };
  },
  methods: {
    async getItems(params) {
      this.loading = true;

      const { result } = await httpService.index(params);

      this.items = result.items;
      this.totalItems = result.totalItems;

      this.loading = false;
    },
    async createCategory() {
      await this.$root.$modal.show(ManageAvatarCategoryModal);
      this.resetList();
    },
    async updateCategory(category) {
      const payload = await this.$root.$modal.show(ManageAvatarCategoryModal, {
        selectedCategory: mapper.transformAvatarCategoryToUpdateModel(category)
      });

      const index = this.items.findIndex(c => c.id === category.id);

      if (index !== -1) {
        this.items.splice(index, 1, {
          ...this.items[index],
          ...payload
        });
      }
    },
    async deleteCategory(id) {
      const confirmed = await this.$root.$modal.show(ConfirmModal, {
        text: this.$i18n.t('avatarCategories.deleteConfirmText')
      });

      if (!confirmed) return;

      await httpService.del(id);
      this.resetList(false);

      this.$notify({
        type: 'success',
        text: this.$i18n.t('avatarCategories.deleteSuccess')
      });
    }
  }
};
</script>
