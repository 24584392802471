export default {
  props: {
    applyFilters: {
      type: Function,
      required: true
    },
    appliedFilters: {
      type: Object,
      required: true
    }
  },
  data() {
    return {
      filters: {}
    };
  },
  created() {
    this.filters = {
      ...this.defaultFilters,
      ...this.appliedFilters
    };
  },
  methods: {
    filter() {
      this.applyFilters(this.filters);
    },
    reset() {
      this.filters = { ...this.defaultFilters };
      this.filter();
    }
  }
};
