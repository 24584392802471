function transformAvatarCategoryToUpdateModel(category) {
  return {
    id: category.id,
    name: {
      en: category.name.en,
      bg: category.name.bg
    }
  };
}

export default {
  transformAvatarCategoryToUpdateModel
};
