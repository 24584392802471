function getFilters(filters) {
  Object.entries(filters).forEach(([key, value]) => {
    if (!value) {
      delete filters[key];
    }
  });

  return filters;
}

function getOptions(options) {
  const { page, itemsPerPage } = options;

  return {
    limit: itemsPerPage,
    skip: (page - 1) * itemsPerPage
  };
}

export default {
  methods: {
    getListParametersFromUrl() {
      if (Object.keys(this.$route.query).length === 0) {
        return {};
      }

      return this.$route.query;
    },
    setListParametersToUrl(filters) {
      this.$router
        .replace({
          name: this.$route.name,
          query: getFilters(filters)
        })
        .catch(err => err);
    },
    mapListParameters(filters, options) {
      return {
        ...getFilters(filters),
        ...getOptions({ ...options })
      };
    }
  }
};
