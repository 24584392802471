import approvalStatuses from '../../../enums/approval.statuses';

export default {
  methods: {
    getApprovalColor(status) {
      if (status === approvalStatuses.approved) {
        return 'success';
      } else if (status === approvalStatuses.pending) {
        return 'warning';
      } else {
        return 'error';
      }
    }
  }
};
