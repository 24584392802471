<template>
  <v-card>
    <v-card-title>
      <span class="headline font-weight-light" v-if="category.id">
        {{ $t('avatarCategories.editCategory') }}
      </span>
      <span class="headline font-weight-light" v-else>
        {{ $t('avatarCategories.createCategory') }}
      </span>
      <v-spacer></v-spacer>
      <v-btn text icon @click="$emit('close')" class="mr-0">
        <v-icon>mdi-close</v-icon>
      </v-btn>
    </v-card-title>
    <v-card-text class="mt-5">
      <v-form ref="manageCategoryForm">
        <v-row>
          <v-col md="12" class="pb-0">
            <h3 class="pb-1">{{ $t('common.name') }}</h3>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="12" md="6">
            <v-text-field
              solo
              v-model="category.name.en"
              :rules="[rules.required]"
              :label="$t('languages.en')"
              class="pl-3"
            ></v-text-field>
          </v-col>
          <v-col cols="12" md="6">
            <v-text-field
              solo
              v-model="category.name.bg"
              :rules="[rules.required]"
              :label="$t('languages.bg')"
              class="pl-3"
            ></v-text-field>
          </v-col>
        </v-row>
      </v-form>
    </v-card-text>
    <v-card-actions>
      <v-spacer></v-spacer>
      <v-btn color="primary" text @click="save()" :loading="loading">
        {{ $t('common.save') }}
      </v-btn>
    </v-card-actions>
  </v-card>
</template>

<script>
import validators from '../../../core/validators';
import httpService from '../services/avatar.categories.http.service';

export default {
  name: 'manage-avatar-category-modal',
  props: {
    selectedCategory: Object
  },
  data() {
    return {
      loading: false,
      category: {
        name: {
          en: '',
          bg: ''
        }
      },
      rules: {
        required: validators.required(this.$i18n.t('common.requiredField'))
      }
    };
  },
  created() {
    if (this.selectedCategory) {
      this.category = this.selectedCategory;
    }
  },
  methods: {
    async create() {
      await httpService.create({ name: this.category.name });

      this.$notify({
        type: 'success',
        text: this.$i18n.t('avatarCategories.createSuccess')
      });

      this.$emit('close');
    },
    async update() {
      await httpService.update(this.category.id, {
        name: this.category.name
      });

      this.$notify({
        type: 'success',
        text: this.$i18n.t('avatarCategories.updateSuccess')
      });

      this.$emit('close', this.category);
    },
    async save() {
      if (!this.$refs.manageCategoryForm.validate()) {
        return;
      }

      this.loading = true;

      if (this.category.id) {
        this.update();
      } else {
        this.create();
      }
    }
  }
};
</script>
